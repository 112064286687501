<template>
  <div class="logo-scroll h-14">
    <div class="logo-wrapper h-14">
      <img src="/img/providers/partner-home-musement-new.svg" alt="musement" class="h-5" />
      <img src="/img/providers/partner-home-viator-new.svg" alt="viator" class="h-5" />
      <img src="/img/providers/partner-home-tiqets-new.svg" alt="tiqets" class="h-5" />
      <img src="/img/providers/partner-home-klook-new.svg" alt="klook" class="h-5" />
      <img src="/img/providers/partner-home-civitatis-new.svg" alt="civitatis" class="h-5" />
      <img src="/img/providers/partner-home-gyg-new.svg" alt="getyourguide" class="h-5" />
      <img src="/img/providers/partner-home-manawa-new.svg" alt="manawa" class="h-5" />
      <img src="/img/providers/partner-home-headout-new.svg" alt="headout" class="h-5" />
      <img src="/img/providers/partner-home-fever-new.svg" alt="fever" class="h-5" />
      <img src="/img/providers/partner-home-civitatis-new.svg" alt="clickandboat" class="h-5" />
      <img src="/img/providers/partner-home-musement-new.svg" alt="musement" class="h-5" />
      <img src="/img/providers/partner-home-viator-new.svg" alt="viator" class="h-5" />
      <img src="/img/providers/partner-home-tiqets-new.svg" alt="tiqets" class="h-5" />
      <img src="/img/providers/partner-home-klook-new.svg" alt="klook" class="h-5" />
      <img src="/img/providers/partner-home-civitatis-new.svg" alt="civitatis" class="h-5" />
      <img src="/img/providers/partner-home-gyg-new.svg" alt="getyourguide" class="h-5" />
      <img src="/img/providers/partner-home-manawa-new.svg" alt="manawa" class="h-5" />
      <img src="/img/providers/partner-home-headout-new.svg" alt="headout" class="h-5" />
      <img src="/img/providers/partner-home-fever-new.svg" alt="fever" class="h-5" />
      <img src="/img/providers/partner-home-civitatis-new.svg" alt="clickandboat" class="h-5" />
    </div>
  </div>
</template>
<script setup></script>
<style scoped>
.logo-scroll {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.logo-wrapper {
  display: flex;
  animation: scrollDesktop 20s linear infinite; /* Slower speed for desktop */
}

.logo-wrapper img {
  margin-right: 2rem; /* Adjust spacing between logos as needed */
}

@keyframes scrollDesktop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scrollMobile {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Media query to adjust speed for mobile */
@media (max-width: 768px) {
  .logo-wrapper {
    animation: scrollMobile 15s linear infinite; /* Faster speed for mobile */
  }
}
</style>
